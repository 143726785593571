.chillguy-container {
  background-color: #eed0a0;
  color: #d0f5e9;
  /* Light green text */
  background-image: url('/public/galaxy.jpg');
  background-size: cover;
  background-position: top center;
  background-blend-mode: multiply;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
  /* To allow absolute positioning of the logo */
}

.chillguy-header {
  text-align: center;
  padding: 2rem;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chillguy-twitter-logo-container {
  margin: 1rem 0;
}

.chillguy-twitter-logo {
  width: 32px;
  height: 32px;
  cursor: pointer;
  /* Add a pointer cursor for the link */
}

.chillguy-title {
  font-size: 7.5rem;
  color: #a29bfe;
  /* Soft purple */
  font-family: 'Caveat Brush', cursive;
  margin: 0;
}

.chillguy-subtitle {
  font-size: 1.2rem;
  color: #81ecec;
  /* Aqua */
  margin-top: 1rem;
  display: none;
}

.chillguy-main {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}

.chillguy-box {
  padding: 1rem;
  margin: 15px 0;
  max-width: 500px;
  text-align: center;
}

.chillguy-box-container {
  margin-top: -10rem;
}


.chillguy-input {
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease;
  box-shadow: 0px 0px 30px #a29bfe;
  width: 100%;
  height: 100px;
  padding: 1rem;
  font-size: 1.2rem;
  color: white;
  border: none;
  border-radius: 20px;
  font-family: 'Pangolin', cursive;
  margin-bottom: 1rem;
  resize: none;
}

.chillguy-input:hover {
  box-shadow: 0px 0px 60px #a29bfe;
}

.chillguy-input:focus {
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 60px #a29bfe;
  height: 150px;
  outline: none;
}

.chillguy-button {
  font-family: 'Caveat Brush', cursive;
  background: linear-gradient(135deg, #130d44, #350ba8);
  color: #fff;
  padding: 0rem 3rem;
  font-size: 3rem;
  border-radius: 30px;
  border: 2px solid #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.chillguy-response-container {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 500px;
  flex-direction: column;
}

.chillguy-text-bubble {
  background: linear-gradient(-45deg, #5243c5, #350ba8);
  color: white;
  font-family: 'Pangolin', cursive;
  padding: 1.5rem;
  border-radius: 20px;
  border: 1px solid #fff;
  font-size: 1.2rem;
  margin-left: 1rem;
  flex-grow: 1;
  width: 100%;
}

.chillguy-response-gif {
  width: 360xp;
  height: 360px;
  margin-top: -80px;
  margin-right: -140px;
}

.chillguy-footer {
  text-align: center;
  padding: 1rem;
  color: #b2bec3;
}

@media screen and (max-width: 768px) {
  .chillguy-header {
    padding: 0;
  }

  .chillguy-button {
    font-size: 2rem;
    padding: 0 3rem;
  }

  .chillguy-box-container {
    margin-top: 0px;
  }

  .chillguy-title {
    font-size: 3rem;
  }

  .chillguy-subtitle {
    display: none;
  }

  .chillguy-twitter-logo {
    width: 16px;
    height: 16px;
  }

  .chillguy-main {
    display: flex;
    flex-direction: column;
  }

  .chillguy-response-gif {
    height: auto;
    margin-top: -50px;
    margin-right: 0;
  }
}